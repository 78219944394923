<template>
  <div>
    <v-container class="mt-n6">
      <v-row>
        <v-col>
          <v-subheader
            class="mb-n2 overline text-truncate"
          >
            Periode
          </v-subheader>
          <v-card
            class="pa-2"
          >
            <filter-date-range
              getTimelineItemsString="nlLrGetAllTimelineItems"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader
            class="mt-n4 mb-n2 overline text-truncate"
          >
            Bronnen
          </v-subheader>
          <v-card
            class="py-2"
          >
            <nl-lr-tree-document-types />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            class="my-14"
          >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FilterDateRange from '@/modules/documentDashboard/components/FilterDateRange.vue';
import NlLrTreeDocumentTypes from '@/modules/documentDashboard/nlLrDashboard/components/NlLrTreeDocumentTypes.vue';

export default {

  components: {
    FilterDateRange,
    NlLrTreeDocumentTypes,
  },

  computed: {
  },

};
</script>
