<!--
  Component which list the stakeholders
-->

<template>
  <v-expansion-panel
  >
    <v-expansion-panel-header>{{ title }}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-select
        v-model="stakeholderCode"
        :items="stakeholdersChoices"
        item-text="name"
        item-value="id"
        label="Stakeholdergroep"
        dense
        @input="setStakeholderCategory"
        value="Kamerleden"
        solo
      />

      <transition-group
        v-if="stakeholders && stakeholders.length > 0"
        tag="div"
        name="slide-up"
        class="stakeholders"
      >
        <list-stakeholders-item
          v-for="(stakeholder, index) in stakeholders"
          :key="index + 0"
          :name="stakeholder.name"
          :first-name="stakeholder.firstName"
          :last-name="stakeholder.lastName"
          :surname-prefix="stakeholder.surnamePrefix"
          :party="stakeholder.party"
          :currently-active="stakeholder.currentlyActive"
          :count="stakeholder.count"
          :min="0"
          :max="max"
        />
      </transition-group>
      <div
        v-if="!stakeholders || stakeholders.length === 0"
        class="w-100 mt-n3 text-center subtitle-1 text--secondary"
      >
        Geen resultaten
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
// import { mapGetters } from 'vuex';
import Config from '@/config';

import ListStakeholdersItem from './ListStakeholdersItem.vue';

export default {
  name: 'ListStakeholder',

  props: {
    title: {
      type: String,
      required: true,
    },
    analyticsConfigKey: {
      type: String,
      required: true,
    },
    analyticsGetter: {
      type: String,
      required: true,
    },
    analyticsConfigDefaultKey: {
      type: String,
      required: true,
    },
    analyticsLocalStorageKey: {
      type: String,
      required: true,
    },
    analyticsFunction: {
      type: String,
      required: true,
    },
  },

  components: {
    // ContentPanel,
    ListStakeholdersItem,
    // ButtonMoreLess,
  },

  data() {
    return {
      stakeholderCode: localStorage.getItem(this.analyticsLocalStorageKey)
        || Config[this.analyticsConfigDefaultKey],
      stakeholdersChoices: Config[this.analyticsConfigKey],
    };
  },

  methods: {
    setStakeholderCategory(category) {
      this.$store.dispatch(this.analyticsFunction, category);
    },
  },

  computed: {
    // ...mapGetters({
    //   stakeholders: 'statisticsStakeholders',
    // }),

    stakeholders() {
      return this.$store.getters[this.analyticsGetter];
    },

    // Returns the highest count value of a stakeholders
    max() {
      return Math.max(
        ...this.stakeholders.map((stakeholder) => stakeholder.count),
      );
    },
  },
};
</script>

<style scoped lang="scss">
.stakeholders {
  padding: padding-content-multiply(2) 0;
}

.stakeholders .stakeholder:last-child {
  margin-bottom: 0;
}
</style>
