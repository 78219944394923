<!--
  Component used to list agendas
-->

<template>
  <div>
    <v-subheader
      v-if="agendas && agendas.length > 0"
      class="text-subtitle-1 text--teal text--darken-1 mt-4"
    >
      Bijeenkomsten
    </v-subheader>
    <div v-if="agendas && agendas.length > 0">
      <list-agenda-item
        v-for="(agenda, index) in agendas.slice(0, size)"
        :key="index + 0"
        :id="agenda.id"
        :title="agenda.title"
        :location="agenda.location"
        :start-time="agenda.startTime ? agenda.startTime : ''"
        :end-time="agenda.endTime ? agenda.endTime : ''"
        :confidentiality="agenda.confidentiality"
      />
      <v-btn
        v-if="!(size >= agendas.length)"
        @click.stop="showMore()"
        block
      >
        Toon meer
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListAgendaItem from './ListAgendaItem.vue';

export default {
  name: 'ListAgenda',

  components: {
    ListAgendaItem,
  },

  data() {
    return {
      size: 5,
    };
  },

  computed: {
    ...mapGetters({
      agendas: 'agendaAll',
    }),
  },

  methods: {
    showMore() {
      this.size += 5;
    },
  },

};
</script>

<style scoped lang="scss">
.calendar-items .calendar-item:last-child {
  margin-bottom: 0;
}

.load-more {
  margin-top: 30px;
}
</style>
