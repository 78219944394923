<template>
  <div>
    <v-container class="mt-n6">
      <v-row>
        <v-col>
          <v-subheader
            class="mb-n2 overline text-truncate"
          >
            Periode
          </v-subheader>
          <v-card
            class="pa-2"
          >
            <filter-date-range
              getTimelineItemsString="nlGetAllTimelineItems"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader
            class="mt-n4 mb-n2 overline text-truncate"
          >
            Minimale match (context)
          </v-subheader>
          <v-card
            class="pa-2"
          >
            <filter-minimal-match-percentage />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader
            class="mt-n4 mb-n2 overline text-truncate"
          >
            Context Filters (AI)
          </v-subheader>
          <v-card
            class="py-2"
          >
            <nl-tree-context />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-subheader
            class="mt-n4 mb-n2 overline text-truncate"
          >
            Bronnen
          </v-subheader>
          <v-card
            class="py-2"
          >
            <nl-tree-document-types />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            class="my-14"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import FilterDateRange from '@/modules/documentDashboard/components/FilterDateRange.vue';
import NlTreeContext from '@/modules/documentDashboard/nlDashboard/components/NlTreeContext.vue';
import NlTreeDocumentTypes from '@/modules/documentDashboard/nlDashboard/components/NlTreeDocumentTypes.vue';

import FilterMinimalMatchPercentage from './FilterMinimalMatchPercentage.vue';

export default {

  components: {
    FilterDateRange,
    FilterMinimalMatchPercentage,
    NlTreeContext,
    NlTreeDocumentTypes,
  },

  computed: {

  },

};
</script>
