<!--
  Form used to save, add and edit searches
-->

<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="768"
      persistent
      scrollable
      v-if="dialogFormSavedSearchesVisible"
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  Zoekopdracht opslaan
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="hideForm"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submitForm()"
          >
            <v-text-field
              v-model="form.title"
              required
              :rules="titleRules"
              label="Titel"
              counter
              maxlength="75"
            />

            <v-checkbox
              v-model="form.sendUpdatesPerEmail.realTime"
              label="Directe e-mail"
              hint="Je ontvangt bij iedere update een e-mail."
            />

            <v-checkbox
              class="mt-n3"
              v-model="form.sendUpdatesPerEmail.daily"
              label="Dagelijkse e-mail"
              hint="Je ontvangt dagelijks een e-mail."
            />

            <v-checkbox
              class="mt-n3"
              v-if="authHasModuleAccess('MC08')"
              v-model="form.sendUpdatesPerEmail.weekly"
              label="Wekelijkse e-mail"
              hint="Je ontvangt wekelijks een e-mail."
            />

            <div class="text-end mr-n4 mb-n3">
              <v-btn
                class="mr-2"
                @click="cancelForm"
              >
                {{ $t('generic.cancel') }}
              </v-btn>

              <v-btn
                color="teal lighten-1"
                class="white--text"
                @click="submitForm"
              >
                {{ $t('generic.save') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SaveSearchesForm',

  props: {
    visible: {
      type: Boolean,
      required: false,
    },
    dashboard: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      valid: false,
      dialog: true,
      form: {
        title: '',
        description: '',
        sendUpdatesPerEmail: {
          realTime: false,
          daily: true,
          weekly: false,
        },
      },
      titleRules: [
        (v) => !!v || 'Geef de zoekopdracht een titel',
        (v) => v.length <= 75 || 'Maximaal 75 tekens',
      ],
      busy: false,
      disabled: false,
    };
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
    }),

    currentSearch() {
      return this.$store.getters[`${this.dashboard}CurrentSearch`];
    },

    dialogFormSavedSearchesVisible() {
      return this.$store.getters[`${this.dashboard}DialogFormSavedSearchesVisible`];
    },

    // Merge the current form with the current search
    search() {
      // We need to make a copy of the currentSearch
      // to avoid manipulating the store directly which causes for example
      // the displayed search item to change without even being saved.
      return { ...this.form, ...this.currentSearch };
    },

    setFormData() {
      this.resetForm();

      // if search has been saved repopulate the form with the values from saved search
      if (this.currentSearch.id) {
        this.setFormWithData();
      }
      return null;
    },
  },

  methods: {
    // This is to avoid a
    setFormWithData() {
      this.form.title = this.currentSearch.title;
      this.form.description = this.currentSearch.description;
      this.form.sendUpdatesPerEmail.realTime = this.currentSearch
        .sendUpdatesPerEmail.realTime;
      this.form.sendUpdatesPerEmail.daily = this.currentSearch
        .sendUpdatesPerEmail.daily;
      this.form.sendUpdatesPerEmail.weekly = this.currentSearch
        .sendUpdatesPerEmail.weekly;
    },
    /**
     * Hides/closes the form
     */
    hideForm() {
      this.resetForm();
      // this.$refs[formName].resetFields();
      this.$store.dispatch(`${this.dashboard}SetDialogFormSavedSearchesVisible`, false);
    },

    /**
     * Resets the form and empties all form elements
     */
    resetForm() {
      this.form = {
        title: '',
        description: '',
        sendUpdatesPerEmail: {
          realTime: false,
          daily: true,
          weekly: false,
        },
      };
    },

    /**
     * Cancel the form
     */
    cancelForm() {
      this.hideForm();
    },

    /**
     * Submits the form
     *
     * @param evt Event of the submit button
     */
    submitForm() {
      if (this.$refs.form.validate()) {
        this.busy = true;
        this.disabled = true;
        this.search.title = this.form.title;
        this.search.description = this.form.description;
        this.search.sendUpdatesPerEmail = this.form.sendUpdatesPerEmail;
        this.$store.dispatch(`${this.dashboard}SaveSearch`, this.search).then(
          () => {
            this.busy = false;
            this.disabled = false;
            this.hideForm();
            this.resetForm();
          }, () => {
            this.busy = false;
            this.disabled = false;
            this.hideForm();
          },
        );
      }
    },
  },

  beforeUpdate() {
    // this is to populate the form in case of editing a saved search.
    this.$nextTick(() => {
      // You can't call computed values as functions.
      // See https://stackoverflow.com/questions/48901546/how-to-write-this-function-as-a-computed-property-in-vue
      // eslint-disable-next-line no-unused-expressions
      this.setFormData;
    });
  },
};
</script>

<style scoped lang="scss">
.spinner {
  top: 2px;
  position: relative;
  left: 4px;
}

</style>

<style lang="scss">
.direct-label {
  margin-top: -20px;
  .el-form-item__label {
    margin-top:10px;
    line-height: 20px;
  }
}
</style>
