<template>
  <feature-teaser-dialog title="Agenda Dashboard ontgrendelen?">
    <p>Wil je het agenda dashboard ontgrendelen? Neem dan gerust contact met ons op.</p>
  </feature-teaser-dialog>
</template>

<script>
import FeatureTeaserDialog from '@/components/FeatureTeaserDialog.vue';

export default {
  components: {
    FeatureTeaserDialog,
  },
};
</script>
