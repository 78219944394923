<template>
  <div>
    <v-dialog
      v-model="dialog"
      :width="(stakeholderSourceListSB.length > 0 && (stakeholderSourceListPP.length > 0))  ? '' : 'fit-content'"
      max-width="max(60%, 768px)"
      persistent
      scrollable
    >
      <v-card>
        <div
          class="d-inline-block text-right align-self-end"
          style="width: 20; margin-right: 2%; margin-top: 2%;"
        >
          <v-icon
            class="mt-n2 mr-n2"
            @click="hideDialog()"
          >
            mdi-close
          </v-icon>
        </div>

        <div
          class='spinner'
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            class="ma-4"
          ></v-progress-circular>
        </div>

        <div
          class="dialogContent"
          v-if="!loading"
        >
          <div
            class="column"
            v-if="stakeholderSourceListSB && stakeholderSourceListSB.length > 0"
          >
            <v-card-title>
              <div class="title">
                Stakeholderbronnen
              </div>
              <div class="subtitle">
                aangesloten voor jouw organisatie:
              </div>
            </v-card-title>
            <ol>
              <li
                v-for="(source, i) in stakeholderSourceListSB"
                :key="i"
              >{{ source }}</li>
            </ol>
          </div>

          <div
            class="column"
            v-if="stakeholderSourceListPP && stakeholderSourceListPP.length > 0"
          >
            <v-card-title>
              <div class="title">
                Politieke partijwebsites
              </div>
              <div class="subtitle">
                aangesloten voor jouw organisatie:
              </div>
            </v-card-title>
            <ol>
              <li
                v-for="(source, i) in stakeholderSourceListPP"
                :key="i"
              >{{ source }}</li>
            </ol>
          </div>
        </div>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { hideDialog } from '@/helpers/dialogHelper';

export default {
  name: 'NlFiltersExplanationDialog',

  data() {
    return {
      dialog: true,
      loading: true,
      stakeholderSourceListSB: [],
      stakeholderSourceListPP: [],
    };
  },

  async mounted() {
    const stakeholderSourceLists = (await this.$store.dispatch('getStakeholderSourceList')).data;
    this.stakeholderSourceListSB = stakeholderSourceLists.SB.sort((a, b) => a.localeCompare(b));
    this.stakeholderSourceListPP = stakeholderSourceLists.PP.sort((a, b) => a.localeCompare(b));
    this.loading = false;
  },

  methods: {
    hideDialog,
  },
};
</script>

<style scoped lang='scss'>
  .v-card {
    height: fit-content;
    padding: 1em;

    .spinner {
      width: fit-content;
    }

    .dialogContent {
      display: flex;
      flex-wrap: wrap;
      margin-top: -2.5em;

      .column {
        flex-grow: 1;
        min-width: 15em;
        margin-right: 2em;

        .v-card__title {
          width: fit-content;

          display: block;
          padding-bottom: 0.2em;
          border-bottom: 1px solid grey;
          margin-bottom: 0.3em;

          .subtitle {
            font-size: 0.7em;
            font-weight: 400;
          }
        }

        ol {
          width: fit-content;

          li::marker {
            color: #656e6d;
          }
        }
      }
    }
  }
</style>
