<template>
  <div>
    <v-menu
      v-model="menuOpen"
      offset-y
    >
      <template
        v-slot:activator="{ on, attrs }"
      >
        <v-text-field
          :value="formattedDateRangeText"
          prepend-icon="mdi-calendar-range"
          color="#0c567a"
          outlined
          dense
          style="height: 40px;"
          autocomplete="off"
          @input="textFieldOnInput"
          v-bind="attrs"
          v-on="on"
        />
      </template>

      <v-list>
        <v-list-item @click="setDateRangeInMonths(1)">
          <v-list-item-title>Vorige maand</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setDateRangeInMonths(6)">
          <v-list-item-title>Vorige 6 maanden</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setDateRangeInMonths(12)">
          <v-list-item-title>Vorige 12 maanden</v-list-item-title>
        </v-list-item>
        <v-list-item @click="setDateRangeToAll()">
          <v-list-item-title>Toon alles</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="modal = true"
        >
          <v-list-item-title>Anders...</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-if="modal"
      v-model="modal"
      persistent
      width="290px"
    >
      <v-date-picker
        ref="datePicker"
        v-model="datePickerDateRange"
        range
        no-title
        locale="nl-nl"
      >
        <v-spacer />
        <v-btn
          color=""
          @click="modal = false"
        >
          Annuleer
        </v-btn>
        <v-btn
          color="teal
          lighten-1"
          class="white--text"
          @click="saveDatePickerDateRange"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data: () => ({
    dateRange: [],
    menuOpen: false,
    datePickerDateRange: [],
    modal: false,
  }),

  props: {
    getTimelineItemsString: {
      type: String,
      required: true,
    },
  },

  methods: {
    setDateRangeInMonths(numOfMonths) {
      const start = new Date(
        (new Date()).getTime() - 3600 * 1000 * 24 * 30 * numOfMonths, // x months back
      );
      start.setHours(0, 0, 0, 0); // ... but start of day

      const end = new Date(); // now
      end.setHours(23, 59, 59, 999); // ... but end of day

      this.applyDateRange([start, end]);
    },

    setDateRangeToAll() {
      const start = new Date(0); // 1 jan 1970

      const end = new Date(); // now
      end.setHours(23, 59, 59, 999); // ... but end of day

      this.applyDateRange([start, end]);
    },

    saveDatePickerDateRange() {
      if (this.datePickerDateRange.length !== 2) return;
      const start = new Date(this.datePickerDateRange[0]);
      start.setHours(0, 0, 0, 0); // start of day

      const end = new Date(this.datePickerDateRange[1]);
      end.setHours(23, 59, 59, 999); // end of day

      this.applyDateRange([start, end]);
      this.modal = false;
    },

    applyDateRange(dateRange) {
      this.dateRange = dateRange;
      const dateRangeISO = {
        startDate: this.dateRange[0].toISOString(),
        endDate: this.dateRange[1].toISOString(),
      };

      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, ...dateRangeISO },
      }).catch(() => {});

      this.$store.dispatch('setDateRange', dateRangeISO);
      this.$store.dispatch(this.getTimelineItemsString);
    },

    textFieldOnInput(dateRangeString) {
      this.menuOpen = false;
      // On user input we have to split a string apart, clean it from whitespace and then format as an array of date objects
      // Check for dates in dd-mm-yyyy tot dd-mm-yyyy notation. The whitespace between the parts may vary
      const valid = /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})\s+\b(\w*tot\w*)\b\s+(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})$/.test(dateRangeString);
      if (valid) {
        const newDateRange = dateRangeString.split('tot')
          .map((item) => moment(item.trim(), ['DD-MM-YYYY']).toDate());
        this.applyDateRange(newDateRange);
      } else {
        this.$store.dispatch('setMessage', {
          message: 'Gebruik "DD-MM-YYYY tot DD-MM-YYYY" notatie',
          type: 'info',
        });
      }
    },
  },

  mounted() {
    this.dateRange = this.$store.getters.searchParameterDateRange?.map((d) => new Date(d));
  },

  computed: {
    formattedDateRangeText() {
      if (!this.dateRange?.length) return '';
      const formattedRange = [moment(this.dateRange[0]).format('DD-MM-YYYY'), moment(this.dateRange[1]).format('DD-MM-YYYY')];
      if (this.dateRange[0].getTime() === 0) formattedRange[0] = ''; // is 1 jan 1970
      return `${formattedRange.join('  tot  ')}`;
    },
  },
};
</script>
