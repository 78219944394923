<!--
  Component which is an item of the stakeholders list
-->

<template>
  <div>
    <div class="stakeholder text--secondary">
      <div
        v-if="currentlyActive || !firstName && !lastName"
        class="stakeholder__progress-bar"
      >
        <div
          class="stakeholder__progress-bar--active"
          role="progressbar"
          :style="{ width: percentage + '%'}"
        />
      </div>
      <div
        v-if="firstName && lastName && currentlyActive"
        class="text-body-2"
      >
        {{ fullName }} <span v-if="party">({{ party }})</span><span v-if="!currentlyActive"> - Gestopt</span>
      </div>
      <div
        v-if="!firstName && !lastName"
        class="text-body-2"
      >
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListStakeholdersItem',

  props: {
    // Name of the stakeholder
    name: {
      type: String,
      required: true,
      default: '',
    },
    firstName: {
      type: String,
      required: false,
      default: '',
    },
    lastName: {
      type: String,
      required: false,
      default: '',
    },
    surnamePrefix: {
      type: String,
      required: false,
    },
    // Party of the stakeholder
    // Gives an error when undefined in full text search
    // so we decided to set it to not required
    party: {
      type: String,
      required: false,
    },
    currentlyActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Amount of stakeholder activitiy
    count: {
      type: Number,
      required: true,
    },
    // Lowest count value possible
    min: {
      type: Number,
      required: true,
    },
    // Highest count value possible
    max: {
      type: Number,
      required: true,
    },
  },

  computed: {
    // Converts the given count to a number between the min and max
    // When min = 0 it acts as a normal progressbar
    percentage() {
      const range = this.max - this.min + 1;
      const relativeCount = this.count - this.min + 1;
      return Math.round((relativeCount / range) * 100);
    },

    fullName() {
      return `${this.firstName}${(this.surnamePrefix) ? ` ${this.surnamePrefix} ` : ' '}${this.lastName}`;
    },
  },
};
</script>

<style scoped lang="scss">
// Default colors, feel free to override in _colors.scss
$color-stakeholder-activity__background:       #e9ecef !default;
// $color-stakeholder-activity__background--active: #1abb9c !default;
$color-stakeholder-activity__background--active: #1abb9c !default;

$progress-bar__height: 10px;

.stakeholder {
  margin-bottom: 10px;

  &__progress-bar {
    height: $progress-bar__height;
    background-color: $color-stakeholder-activity--background;

    &--active {
      transition: width 1s;
      background: $color-stakeholder-activity--background--active !important;
      height: $progress-bar__height;
    }
  }
}
</style>
