<!--
  Component used to display an agenda in the agenda list component
-->

<template>
  <v-card
    class="mb-2"
    outlined
    @click.stop.prevent="goToUrlConditionally()"
  >
    <v-card-text>
      <div class="">
        {{ meetingDateFormatted }} {{ dateRangeFormatted }}
      </div>
      <h6 class="text-subtitle-1">
        {{ title }}
      </h6>
      <div class="d-flex mt-2 justify-space-between">
        <span>
          {{ location }}
        </span>
        <v-spacer />
        <span>
          <confidentiality-status :confidentiality="confidentiality" />
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// App specific
import ConfidentialityStatus from '@/components/Confidentiality.vue';

export default {
  name: 'AgendaItem',

  components: {
    ConfidentialityStatus,
  },

  props: {
    // Database ID of the agenda
    id: {
      type: String,
      required: true,
    },
    // Agenda title
    title: {
      type: String,
      required: true,
    },
    // Location name
    location: {
      type: String,
      required: true,
    },
    // State of confidentiality i.e. "openbaar" or "besloten"
    confidentiality: {
      type: String,
      required: false,
      default: 'default',
    },
    // Start time of the meeting
    startTime: {
      type: String,
      required: true,
    },
    // End time of the meeting
    endTime: {
      type: String,
      required: true,
    },
  },

  computed: {
    meetingDateFormatted() {
      return this.$moment(this.startTime).format('D MMMM YYYY');
    },
    startTimeFormatted() {
      return this.$moment(this.startTime).format('HH:mm');
    },
    endTimeFormatted() {
      return this.$moment(this.endTime).format('HH:mm');
    },
    dateRangeFormatted() {
      const from = (this.startTime && this.startTime !== this.endTime) ? `van ${this.startTimeFormatted}` : '';
      const to = this.endTime ? ` tot ${this.endTimeFormatted}` : '';
      return `${from}${to} uur`;
    },
  },

  methods: {
    goToUrlConditionally() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$router.push({ path: `/agenda/${this.id}?query=${this.$route.query.query}` });
      } else {
        window.open(`/agenda/${this.id}?query=${this.$route.query.query}`, '_blank');
      }
    },
  },
};
</script>

<style scoped lang="scss">
// Default colors, feel free to override in _colors.scss
$color-location--default: #32bc9e !default;
$color-location--text: #7e7e7e !default;

.calendar-item {
  overflow: auto;
  margin-bottom: padding-content-multiply(8);

  &__title {
    font-size: 1.1em;
    line-height: 1.5em;
  }

  &__location,
  &__timestamp {
    overflow: auto;
    color: $color-location--text;
    font-weight: 600;
  }

  &__timestamp {
    .timestamp__date {
      float: left;
    }
    .timestamp__time {
      float: right;
    }
  }

  .location {
    &__place {
      float: left;
    }
    &__access {
      float: right;
    }
  }
}
</style>
